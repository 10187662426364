<template>
	<div class="mine page">
		<div class="page-bg"></div>
		<div class="wrapper">
			<van-pull-refresh v-model="isLoading">
				<div class="header">
					<div class="cskh" @click="toService()"><img src="/img/mine/service.png" /></div>
					<van-nav-bar class="nav-bar">
						<template #right>
							<svg @click="$router.push({path: 'Language'})" xmlns="http://www.w3.org/2000/svg" class="icon-language" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
								<path stroke="none" d="M0 0h24v24H0z" fill="none" />
								<path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
								<path d="M3.6 9h16.8" />
								<path d="M3.6 15h16.8" />
								<path d="M11.5 3a17 17 0 0 0 0 18" />
								<path d="M12.5 3a17 17 0 0 1 0 18" />
							</svg>
							<van-icon name="setting-o" @click="showSetting()" color="#fff" />
						</template>
					</van-nav-bar>
					<div class="user-wrapper" @click="doLogin()">
						<van-image round class="user_img" :src="this.userInfo.header_img">
							<template v-slot:loading>
								<van-loading type="spinner" />
							</template>
						</van-image>
						<div class="login-content">
							<p class="login-btn">{{ this.userInfo.username }}</p>
							<p class="login-label" v-if="this.userInfo.money" style="color:#c89d60;font-weight:bold"><img :src="`/img/vip/v${this.userInfo.vip ? this.userInfo.vip : '1'}.png`" width="40" style="position:relative;top:10px;margin-right:2px" /> VIP {{ this.userInfo.vip ? this.userInfo.vip : "1" }}</p>
						</div>
					</div>
				</div>
				<div class="content">
					<div class="finance">
						<div class="finance-item" @click="doPay()">
							<van-icon class="icon" style="" name="peer-pay" />
							<span class="text">{{ $t("recharge") }}</span>
						</div>
						<div class="line"></div>
						<div class="finance-item" @click="doWithdrawal()">
							<van-icon class="icon" name="idcard" />
							<span class="text">{{ $t("withdraw") }}</span>
						</div>
					</div>
					<div v-if="this.userInfo.money" class="wallet">
						<div class="part-1 van-hairline--bottom">
							<p class="flex-1 font-20 font-primary-color">{{ $t("your_point") }}</p>
							<!--<span class="font-28 font-gray">Chi tiết</span>
                  <van-icon class="font-gray" style="font-size: 14px" name="arrow" />-->
						</div>
						<div class="part-2">
							<p class="balance van-ellipsis">{{ Number(this.userInfo.money).toLocaleString() }}</p>
							<span class="font-20 font-gray" style="font-size:18px">{{ $t("money") }}</span>
							<div class="refresh-btn" @click="refresh()"><van-icon name="replay" /></div>
						</div>
						<div class="part-2">
							<p class="balance van-ellipsis">{{ this.userInfo.credit ? this.userInfo.credit : "0" }}</p>
							<span class="font-20 font-gray" style="font-size:18px">{{ $t("credit") }}</span>
							<div class="refresh-btn" @click="refresh()"><van-icon name="replay" /></div>
						</div>
					</div>
					<div :style="{marginTop: menu_top + 'px'}" class="menu">
						<div class="menu-item" @click="$router.push({path: '/Personalreport'})">
							<van-image class="menu-item-icon" src="img/mine/baobiao.svg">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="menu-item-label">{{ $t("account_detail") }}</span>
						</div>
						<div class="menu-item" @click="$router.push({path: '/Infomation'})">
							<van-image class="menu-item-icon" src="img/mine/user.svg">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="menu-item-label">{{ $t("info") }}</span>
						</div>
						<div class="menu-item" @click="$router.push({path: '/WithdrawRecord'})">
							<van-image class="menu-item-icon" src="img/mine/mingxi.svg">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="menu-item-label">{{ $t("history_withdraw") }}</span>
						</div>
						<div class="menu-item" @click="$router.push({path: '/RechargeRecord'})">
							<van-image class="menu-item-icon" src="img/mine/mingxi.svg">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="menu-item-label">{{ $t("history_recharge") }}</span>
						</div>
						<!--<div class="menu-item" @click="exit()">
                <van-image class="menu-item-icon" src="img/mine/mingxi.svg">
                  <template v-slot:loading>
                    <van-loading type="spinner"/>
                  </template>
                </van-image>
                <span class="menu-item-label" >Liên kết ngân hàng</span>
              </div>-->
						<div class="menu-item" @click="$router.push({path: '/GameRecord'})">
							<van-image class="menu-item-icon" src="img/mine/youxi.svg">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="menu-item-label">{{ $t("history_play") }}</span>
						</div>
						<!--<div class="menu-item" @click="$router.push({path: '/Setbank'})">
							<van-image class="menu-item-icon" src="img/mine/gonggao.svg">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="menu-item-label">{{ $t("bind_bank") }}</span>
						</div>-->
						<div class="menu-item" @click="toNotice()">
							<van-image class="menu-item-icon" src="img/mine/gonggao.svg">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="menu-item-label">{{ $t("notice") }}</span>
						</div>
						<div class="menu-item" @click="toService()">
							<van-image class="menu-item-icon" src="img/mine/kefu_1.svg">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="menu-item-label">{{ $t("service") }}</span>
						</div>
					</div>
				</div>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			userInfo: {},
			menu_top: 40,
			isLoading: false,
		};
	},
	methods: {
		refresh() {
			this.isLoading = true;
			setTimeout(() => {
				this.isLoading = false;
				if (localStorage.getItem("token")) {
					this.$toast(this.$t("renew"));
				} else {
					this.$router.push({path: "/Login"});
				}
			}, 500);
		},
		exit() {
			this.$toast("Bạn đã liên kết ngân hàng, để chỉnh sửa vui lòng liên hệ CSKH");
		},
		showSetting() {
			if (localStorage.getItem("token")) {
				this.$router.push({path: "/Setting"});
			} else {
				this.$router.push({path: "/Login"});
			}
		},
		toNotice() {
			if (localStorage.getItem("token")) {
				this.$router.push({path: "/Notice"});
			} else {
				this.$router.push({path: "/Login"});
			}
		},
		onRefresh() {
			setTimeout(() => {
				this.isLoading = false;
				if (localStorage.getItem("token")) {
					this.getUserInfo();
					this.$toast(this.$t("renew"));
				} else {
					this.$router.push({path: "/Login"});
				}
			}, 500);
		},
		doLogin() {
			if (localStorage.getItem("token")) {
				this.$router.push({path: "/Infomation"});
			} else {
				this.$router.push({path: "/Login"});
			}
		},
		doPay() {
			if (localStorage.getItem("token")) {
				this.$router.push({path: "/Recharge"});
				//this.$toast(this.$t('pls_contact'));
			} else {
				this.$router.push({path: "/Login"});
			}
		},
		doWithdrawal() {
			this.$http({
				method: "get",
				url: "user_get_bank",
			}).then(res => {
				if (res.data.is_bank) {
					this.$router.push("withdraw");
				} else {
					this.$router.push("BindCard");
					//this.$toast.fail("Vui lòng thiết lập ngân hàng!");
				}
			});
		},
		toService() {
			if (this.$store.getters.getBaseInfo.iskefu == 1) {
				this.$router.push("ServiceOnline");
			} else {
				this.$toast.fail("Tính năng bị vô hiệu hóa!");
			}
		},
		getUserInfo() {
			this.$http({
				method: "get",
				url: "user_info",
			}).then(res => {
				if (res.code === 200) {
					this.userInfo = res.data;
					this.menu_top = 15;
					if (this.userInfo.status !== 1) {
						this.$toast(this.t("offline"));
						localStorage.clear();
						this.$router.push({path: "/Login"});
					}
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
	},
	created() {
		if (localStorage.getItem("token")) {
			this.getUserInfo();
		} else {
			this.$router.push("Login");
			this.userInfo.username = "Đăng nhập / Đăng ký";
			this.userInfo.ip = null;
			this.userInfo.header_img = "img/mine/avatar.png";
		}
	},
};
</script>

<style scoped>
.page {
	background-color: #f2f2f5;
}
.mine {
	position: relative;
	bottom: 10px;
	background: #f2f2f5;
}
.mine .wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}
.nav-bar {
	background: linear-gradient(90deg, #775fd9, #c24491);
}
.mine .header {
	background: linear-gradient(90deg, #775fd9, #c24491);
	padding-bottom: 50px;
}
::v-deep .van-nav-bar__right {
	top: 10px;
}
::v-deep .van-nav-bar__content {
	height: 50px;
}
::v-deep .van-hairline--bottom::after {
	border-bottom-width: 0px;
}
.mine .header .van-nav-bar .van-icon {
	font-size: 20px;
}
.mine .header .user-wrapper {
	display: flex;
	align-items: center;
	margin: 0px 25px 0px 25px;
}
.mine .user_img {
	height: 60px;
	width: 60px;
}
::v-deep .van-loading__spinner {
	height: 50px;
	width: 50px;
}
::v-deep .van-image__error-icon {
	font-size: 30px;
}
.mine .header .user-wrapper .login-content {
	flex: 1;
	margin-left: 15px;
}
.mine .header .user-wrapper .login-content .login-btn {
	display: inline-block;
	font-size: 20px;
	line-height: 0px;
	color: #fff;
}
.mine .header .user-wrapper .login-content .login-label {
	margin-top: 0px;
	font-size: 22px;
	color: hsla(0, 0%, 100%, 0.8);
	display: inline-block;
	margin-left: 20px;
}
.mine .page-bg {
	height: 100vh;
	background: linear-gradient(90deg, #775fd9, #c24491);
	background: #f2f2f5;
}
.mine .content {
	position: relative;
	padding: 10px 15px 0;
	height: auto;
	background-color: #f2f2f5;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
	color: #ffffff;
	font-size: 16px;
	display: none !important;
}

.mine .wrapper .content .finance {
	position: absolute;
	display: flex;
	align-items: center;
	top: -28px;
	left: 20px;
	right: 20px;
	height: 65px;
	background-color: #fff;
	border-radius: 15px;
	box-shadow: 0 1.5px 1px 0 #e4e4e7;
}
.mine .wrapper .content .finance .line {
	width: 3px;
	height: 40px;
	background-color: #ccc;
}
.mine .wrapper .content .finance .finance-item {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}
.mine .wrapper .content .finance .finance-item .text {
	margin-left: 10px;
	font-size: 15px;
	color: #775fd9;
	font-weight: 500;
}
.mine .wrapper .content .finance .finance-item .icon {
	font-size: 20px;
	color: #775fd9;
}
.mine .wrapper .content .menu {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	background-color: #fff;
	border-radius: 15px;
	box-shadow: 0 1.5px 1px 0 #e4e4e7;
}
.mine .wrapper .content .menu .menu-item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 50%;
	height: 70px;
	line-height: 1.4;
}
.mine .wrapper .content .menu .menu-item:nth-child(even) {
	padding-right: 10px;
}
.mine .wrapper .content .menu .menu-item:nth-child(even) .menu-item-icon {
	margin-left: 5px;
}
.mine .wrapper .content .menu .menu-item .menu-item-label {
	font-size: 14px;
	color: #868686;
	font-weight: 500;
	width: 100%;
}
.mine .wrapper .content .menu .menu-item .menu-item-icon {
	margin: 15px 5px 15px 15px;
	width: 35px;
	height: 35px;
	-o-object-fit: contain;
	object-fit: contain;
}
.mine .wrapper .content .wallet {
	margin-top: 50px;
	padding: 0 20px;
	background-color: #fff;
	border-radius: 15px;
	box-shadow: 0 1.5px 1px 0 #e4e4e7;
}
.mine .wrapper .content .wallet .part-1 {
	display: flex;
	align-items: center;
	height: 50px;
}
.mine .wrapper .content .wallet .font-primary-color {
	color: #8c71d2;
	font-size: 15px;
	margin: 20px 0 5px;
}
.font-gray {
	color: #868686;
}
.mine .wrapper .content .wallet .part-2 {
	display: flex;
	align-items: center;
	height: 80px;
}
.mine .wrapper .content .wallet .part-2 .balance {
	flex: 1;
	font-size: 28px;
	color: #775fd9;
	font-weight: 700;
}
.mine .wrapper .content .wallet .van-hairline--bottom::after {
	border-bottom-width: 3px;
}
.mine .wrapper .content .wallet .part-2 .refresh-btn {
	margin-left: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 35px;
	height: 35px;
	font-size: 18px;
	border-radius: 50%;
	color: #ffffff;
	background-color: #c24491;
}
.cskh {
	position: absolute;
	width: 24px;
	height: 24px;
	top: 18px;
	right: 81px;
	z-index: 2;
}
.cskh img {
	width: 100%;
	height: 100%;
}
.icon-language {
	margin-right: 10px;
}
</style>
